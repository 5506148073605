@import 'src/common/css/global/_base';

/*================================
	modules
=================================*/
.mt0 {
	margin-top: 0 !important;
}
.mb0 {
	margin-bottom: 0 !important;
}
.mb140-100 {
	@include pc() {
		margin-bottom: 140px;
	}
	@include sp() {
		margin-bottom: 100px;
	}
}

.mt20 {
	margin-top: 20px !important;
}

.mt25 {
	margin-top: 25px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mt40 {
	margin-top: 40px !important;
}

.mt50 {
	margin-top: 50px !important;
}

.mt50-25{
	@include pc() {
		margin-top: 50px;
	}
	@include sp() {
		margin-top: 25px;
	}
}

.mt50-0{
	@include pc() {
		margin-top: 50px;
	}
	@include sp() {
		margin-top: 0;
	}
}

.align-center {
	text-align: center !important;
}

.align-right {
	text-align: right !important;
}

.md_h1 {
	color: $main-color;
	line-height: 1.4;
	font-weight: 500;
	@include pc() {
		margin-top: 100px;
		margin-bottom: 100px;
		font-size: 4.2rem;
	}
	@include sp() {
		margin-top: 40px;
		margin-bottom: 60px;
		font-size: 3rem;
	}
}

.md_h2 {
	line-height: 1.4;
	font-weight: 500;
	border-bottom: 2px solid $main-color;
	position: relative;
	
	@include pc() {
		margin-top: 100px;
		margin-bottom: 50px;
		padding-bottom: 18px;
		font-size: 3.4rem;
	}
	@include sp() {
		margin-top: 60px;
		margin-bottom: 25px;
		padding-bottom: 15px;
		font-size: 2.4rem;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 50px;
		height: 2px;
		background-color: $sub-color;
	}

	& + .md_txt {
		@include pc() {
			margin-top: 50px;
		}
		@include sp() {
			margin-top: 25px;
		}
	}
}

.md_h3 {
	padding-left: 10px;
	line-height: 1.4;
	font-weight: 500;
	position: relative;
	
	@include pc() {
		margin-top: 70px;
		margin-bottom: 30px;
		font-size: 2.5rem;
	}
	@include sp() {
		margin-top: 55px;
		margin-bottom: 20px;
		font-size: 2.2rem;
	}

	&::before {
		content: '';
		width: 2px;
		height: 30px;
		background-color: $main-color;
		position: absolute;
		left: 0;
		@include pc() {
			top: 4px;
		}
		@include sp() {
			top: 2px;
		}
	}

	& + .md_txt {
		@include pc() {
			margin-top: 30px;
		}
		@include sp() {
			margin-top: 20px;
		}
	}
}

.md_h4 {
	color: $main-color;
	line-height: 1.4;
	font-weight: 500;
	
	@include pc() {
		margin-top: 60px;
		margin-bottom: 25px;
		font-size: 2.2rem;
	}
	@include sp() {
		margin-top: 50px;
		margin-bottom: 25px;
		font-size: 1.8rem;
	}

	& + .md_txt {
		margin-top: 25px;
	}
}

.md_h5 {
	font-weight: 500;
	@include pc() {
		font-size: 2rem;
	}
	@include sp() {
		font-size: 1.7rem;
	}
}

.md_txt {
	font-size: 1.6rem;
	line-height: 2;
}

.md_ul {
	margin-top: 25px;
	margin-bottom: 25px;

	li {
		line-height: 2;
		padding-left: 16px;
		position: relative;
		@include pc() {
			font-size: 1.6rem;
		}
		@include sp() {
			font-size: 1.5rem;
		}

		& + li {
			margin-top: 10px;
		}

		&::before {
			content: '';
			width: 6px;
			height: 6px;
			background-color: $main-color;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 13px;
		}
	}

	.md_ol {
		margin-top: 10px;
	}
}

.md_ol {
	margin-top: 25px;
	margin-bottom: 25px;
	counter-reset: number 0;

	li {
		line-height: 2;
		padding-left: 22px;
		position: relative;
		text-indent: -22px;
		@include pc() {
			font-size: 1.6rem;
		}
		@include sp() {
			font-size: 1.5rem;
		}

		& + li {
			margin-top: 10px;
		}

		&::before {
			font-weight: 500;
			color: $main-color;
			counter-increment: number 1;
			content: counter(number) ". ";
			background-color: transparent;
			border-radius: inherit;
			position: relative;
			left: 0;
			top: 0;
			margin-right: 3px;
		}

		&:nth-child(n + 10) {
			padding-left: 30px;
			text-indent: -30px;
		}
	}

	&.kana_list {
		counter-reset: number katakana;

		@include sp() {
			margin-top: 15px;
		}

		li {
			padding-left: 28px;

			&::before {
				font-weight: 500;
				color: $main-color;
				counter-increment: number katakana;
				content: counter(number, katakana) ". ";
				background-color: transparent;
				border-radius: inherit;
				width: 13px;
				height: 13px;
				top: 0;
			}
		}
	}
}

.md_anchor {
	display: flex;
	flex-wrap: wrap;
	
	@include pc() {
		margin-top: 100px;
		margin-bottom: 85px;
	}
	@include sp() {
		margin-top: 45px;  /*liのmt:15を引く*/
		margin-bottom: 55px;
		justify-content: space-between;
	}

	li {
		@include pc() {
			margin-right: 40px;
			min-width: 180px;

			&:nth-child(n + 5) {
				margin-top: 25px;
			}
		}
		@include sp() {
			min-width: calc(50% - 12px);
			margin-top: 15px;
		}

		a {
			width: 100%;
			display: inline-block;
			position: relative;
			border-bottom: 1px solid #ccd2d6;
			box-sizing: border-box;
			@include pc() {
				padding-bottom: 18px;
				padding-right: 30px;
				font-size: 1.6rem;
			}
			@include sp() {
				padding-bottom: 15px;
				padding-right: 25px;
				font-size: 1.5rem;
			}

			&::before {
				content: '';
				width: 100%;
				height: 1px;
				background-color: $main-color;
				transition: transform .3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
				transform: scale(0, 1);
				transform-origin: right top;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			&::after {
				content: '';
				width: 16px;
				height: 9px;
				position: absolute;
				top: 8px;
				right: 0;
				background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
				background-size: 100%;
				transform: rotate(90deg);
			}

			&:hover {		
				&::before {
					transform-origin: left top;
					transform: scale(1, 1);
				}
			}
		}
	}

	&.anchor_4col {
		li {
			@include pc() {
				width: calc(100% / 4 - 30px);

				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}
}

.md_content {
	@include pc() {
		width: 1200px;
		margin: 0 auto;
	}
	@include sp() {
		margin: 0 15px;
	}
}

.md_2col {
	display: flex;
	flex-wrap: wrap;
	@include pc() {
		justify-content: space-between;
	}
	@include sp() {
		flex-direction: column;
	}

	> figure {
		@include pc() {
			width: 455px;

			&:nth-child(n + 3) {
				margin-top: 40px;
			}
		}
		@include sp() {
			width: 100%;
			margin-top: 40px;
		}
	}

	&_item {
		@include pc() {
			width: 455px;

			&:nth-child(n + 3) {
				margin-top: 40px;
			}
		}
		@include sp() {
			width: 100%;
			margin-top: 40px;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&_head {
		.md_h5 {
			margin-top: 20px;
		}
		.md_txt {
			margin-top: 10px;
		}
	}

	&_ttlLarge {
		position: relative;
		line-height: 1.4;

		@include pc() {
			font-size: 2.5rem;
			padding-bottom: 15px;
		}
		@include sp() {
			font-size: 2rem;
			padding-bottom: 12px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 30px;
			height: 2px;
			background-color: $sub-color;
		}

		& + .md_txt {
			@include pc() {
				margin-top: 30px;
			}
			@include sp() {
				margin-top: 20px;
			}
		}
	}

	&_txt {
		@include pc() {
			width: 455px;
		}
		@include sp() {
			width: 100%;
			margin-top: 25px;
		}
	}

	figcaption {
		line-height: 1.6;
		@include pc() {
			font-size: 1.5rem;
			margin-top: 20px;
		}
		@include sp() {
			font-size: 1.4rem;
			margin-top: 15px;
		}
	}
}

.md_dlimg2col {
	display: flex;
	flex-wrap: wrap;

	@include pc() {
		justify-content: space-between;
	}
	@include sp() {
		flex-direction: column-reverse;
	}
	&:first-child {
		@include pc() {
			margin-top: 60px;
		}
		@include sp() {
			margin-top: 25px;
		}
	}
	&_txt {
		width: 100%;
		@include pc() {
			width: 450px;
		}
	}
	&_img {
		width: 100%;
		@include pc() {
			width: 465px;
			height: 350px;
			margin-top: -55px;
		}
		@include sp() {
			height: 260px;
			margin-bottom: 40px;
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	dl {
		margin-top: 25px;
		display: flex;

		&:first-child {
			margin-top: 0;
		}

		dt {
			font-weight: 500;
			line-height: 1.5;
			@include pc() {
				width: 70px;
				margin-right: 20px;
				font-size: 1.6rem;
			}
			@include sp() {
				width: 65px;
				margin-right: 15px;
				font-size: 1.5rem;
			}
		}
		dd {
			line-height: 1.5;
			flex: 1;		
			@include pc() {
				font-size: 1.6rem;
			}
			@include sp() {
				font-size: 1.5rem;
			}
		}
	}
}

.md_dl_wrap {
	@include pc() {
		margin-left: 10px;
	}
	@include sp() {
		margin: 0 10px;
	}

	& + .md_dl_wrap {
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid #ccd2d6;
	}

	.md_h3 {
		font-weight: 500;
		@include pc() {
			margin-top: 30px;
			font-size: 1.6rem;
		}
		@include sp() {
			margin-top: 15px;
			font-size: 1.5rem;
		}

		&::before {
			height: 20px;
			width: 1px;
			background-color: $sub-color;
			@include pc() {
				top: 2px;
			}
			@include sp() {
				top: 1px;
			}
		}
	}
}

.md_dl_label {
	display: flex;
	flex-wrap: wrap;

	@include pc() {
		margin-top: 20px;
	}
	@include sp() {
		margin-top: 15px;
	}
	span {
		display: inline-block;
		color: $main-color;
		line-height: 1.2;
		padding: 9px 13px;
		margin-top: 10px;
		margin-right: 10px;
		border: 1px solid #ccd2d6;
		@include pc() {
			font-size: 1.5rem;
		}
		@include sp() {
			font-size: 1.4rem;
		}
	}
}

.md_note {
	margin-top: 10px;
	padding-left: 20px;
	color: #7a7a7a;
	text-indent: -2rem;

	@include pc() {
		font-size: 1.4rem;
	}
	@include sp() {
		font-size: 1.3rem;
	}
	
	&::before {
		content: '\203b';
		color: #7a7a7a;
		
		@include pc() {
			margin-right: 7px;
			font-size: 1.4rem;
		}
		@include sp() {
			margin-right: 5px;
			font-size: 1.3rem;
		}
	}
}