@import 'src/common/css/global/_base';

/*================================
	header
=================================*/
header {
	@include pc() {
		min-width: 1260px;
	}
}

.pc-header {
	width: 100%;
	min-width: 1260px;
	height: 100px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	border-bottom: 1px solid transparent;
	background-color: #fff;
	box-sizing: border-box;
	transition: background-color .2s ease;
	transition: transform .4s ease;
	margin-left: auto;

	@include sp() {
		display: none;
	}

	.-logo_white {
		display: none;
	}
	.-logo_blue {
		display: block;
	}

	&.is-white {
		// border-bottom: 1px solid #ccd2d6;
		.-logo_white {
			display: none;
		}
		.-logo_blue {
			display: block;
		}
	}

	&.is-transparent {
		background-color: transparent;

		.-logo_white {
			display: block;
		}
		.-logo_blue {
			display: none;
		}
		.pc-header_nav_item {
			color: #fff;
			a {
				color: #fff;
			}
			&::after {
				background: url(/common/img/icon_arrow_head_white.png) no-repeat center;
				background-size: contain;
			}
		}
		.-nav_recruit {
			.pc-header_nav_item {
				&::before {
					background: linear-gradient(to right,#fff 0,#fff 100%);
				}
				&::after {
					background: url(/common/img/icon_blank_white.png) no-repeat center;
					background-size: contain;
				}
				&:hover {
					a {
						color: #fff;
					}
					&::before {
						background: linear-gradient(to right,#fff 0,#fff 100%);
					}
				}
			}
		}
		.-nav_news {
			.pc-header_nav_item {
				&::before {
					background: linear-gradient(to right,#fff 0,#fff 100%);
				}

				&:hover {
					a {
						color: #fff;
					}
					&::before {
						background: linear-gradient(to right,#fff 0,#fff 100%);
					}
				}
			}
		}
	}

	&.is-hide {
		transform: translateY(-100%);
	}

	&.is-fixed {
		background-color: #fff;
		border-bottom: 1px solid #ccd2d6;
		transform: translateY(0);

		.-logo_white {
			display: none;
		}
		.-logo_blue {
			display: block;
		}
		.pc-header_nav_item {
			color: #222;

			&::after {
				background: url(/common/img/icon_arrow_head_blue.png) no-repeat center;
				background-size: contain;
				transform: rotate();
			}

			a {
				color: #222;
			}
		}
		.pc-header_nav_drop {
			border-top: none;
			border-bottom: 1px solid #ccd2d6;;
		}
		.-nav_recruit {
			.pc-header_nav_item {
				&::after {
					background: url(/common/img/icon_blank_blue.png) no-repeat center;
					background-size: contain;
				}
				&:hover {
					a {
						color: $main-color;
					}
					&::before {
						background: linear-gradient(to right,$main-color 0,$main-color 100%);
					}
				}
			}
		}
		.-nav_news {
			.pc-header_nav_item {
				&:hover {
					a {
						color: $main-color;
					}
					&::before {
						background: linear-gradient(to right,$main-color 0,$main-color 100%);
					}
				}
			}
		}
	}

	&_inner {
		max-width: calc(100% - 30px);
		min-width: 1260px;
		height: 100px;
		margin-left: auto;
		display: flex;
		align-items: center;
	}

	&_logo {
		width: 266px;
		margin-left: 20px;
	}

	&_nav {
		width: 100%;
		max-width: calc(100% - 609px);
		min-width: 677px;
		margin-left: auto;
		margin-right: 22px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		> li {
			margin-left: 25px;

			&:first-child {
				margin-left: 0;
			}

			&.is-active {
				.pc-header_nav_item {
					color: $main-color;

					&::before {
						transform: scaleX(1);
					}
					&::after {
						transform: rotate(180deg);
					}
				}
			}

			&.is-current {
				.pc-header_nav_item {
					color: $main-color;

					&::before {
						transform: scaleX(1);
					}
				}
			}

			.pc-header_nav_item {
				position: relative;
				font-size: 1.6rem;
				padding-right: 20px;
				cursor: pointer;
				color: #222;
				transition: all .2s ease;

				&::after {
					background: url(/common/img/icon_arrow_head_blue.png) no-repeat center;
					background-size: contain;
					transform: rotate();
				}

				a {
					font-size: 1.6rem;
					transition: all .2s ease;
				}

				&::before {
					content: '';
					position: absolute;
					width: 100%;
					height: 2px;
					left: 0;
					bottom: -10px;
					background: linear-gradient(to right,$main-color 0,$main-color 100%);
					transform-origin: right top;
					transition: transform .8s cubic-bezier(.19,1,.22,1);
					transform: scaleX(0);
				}
				&::after {
					content: '';
					position: absolute;
					width: 11px;
					height: 6px;
					background: url(/common/img/icon_arrow_head_blue.png) no-repeat center;
					background-size: 100%;
					right: 0;
					top: calc(50% - 3px);
					transition: all .2s ease;
				}

				&:hover {
					color: $main-color;
					a {
						color: $main-color;
					}
					&::before {
						transform-origin: left top;
						transform: scaleX(1);
					}

					&:not(:last-child){
						&::after {
							transform: rotate(180deg);
						}
					}

				}
			}

			&.-nav_recruit {
				.pc-header_nav_item {
					&::before {
						background: linear-gradient(to right,$main-color 0,$main-color 100%);
					}
					&::after {
						height: 11px;
						background: url(/common/img/icon_blank_blue.png) no-repeat center;
						background-size: 100%;
						top: calc(50% - 5px);
					}

					&:hover {
						&::before {
							background: linear-gradient(to right,$main-color 0,$main-color 100%);
						}
					}
				}
			}

			&.-nav_news {
				.pc-header_nav_item {
					padding-right: 0;
					&::before {
						background: linear-gradient(to right,$main-color 0,$main-color 100%);
					}
					&::after {
						display: none;
					}

					&:hover {
						&::before {
							background: linear-gradient(to right,$main-color 0,$main-color 100%);
						}
					}
				}
			}
		}
	}
	&_search {
		width: 80px;
		height: 100%;
		background-color: $sub-color;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .2s ease;
		cursor: pointer;

		&:hover {
			background-color: #1083c5;
		}

		figure {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&.is-active {
				.icon-search {
					opacity: 0;
				}
				.icon-close {
					opacity: 1;
				}
			}
		}

		img {
			width: 29px;
			transition: all .2s ease;
		}

		.icon-close {
			opacity: 0;
			position: absolute;
			top: calc(50% - 15px);
			left: calc(50% - 15px);
		}

		form {
			width: 800px;
			margin: 0 auto;
			position: relative;
		}
		input {
			width: 100%;
			height: 60px;
			padding: 19px 25px;
			box-sizing: border-box;
			font-size: 1.6rem;
			background-color: #f9f8fb;
			transition: all .2s ease;
			border: 1px solid #ccd2d6;

			&:focus {
				outline: 2px solid $sub-color;
			}

			&:hover {
				background-color: #f3f2f8;
			}
		}
		button {
			width: 60px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .2s ease;
			font-size: 0;
			background: $sub-color url(/common/img/icon_search_white.png) center center no-repeat;
			background-size: 24px 24px;

			&:hover {
				background-color: #1083c5;
			}

			img {
				width: 24px;
			}
		}

		.pc-header_nav_drop {
			z-index: 9;
		}
	}
	&_faq {
		width: 183px;
		height: 100%;

		a {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			transition: all .2s ease;
			background-color: $main-color;

			&:hover {
				background-color: #000047;
			}
		}

		img {
			width: 32px;
		}

		p {
			color: #fff;
			line-height: 1.2;
			margin-top: 13px;
			font-size: 1.6rem;
		}
	}

	&_nav_drop {
		width: 100%;
		min-width: 1260px;
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 100px;
		display: none;
		border-top: 1px solid #ccd2d6;
		// z-index: 10;

		&_inner {
			width: 1200px;
			padding: 50px 0;
			margin: 0 auto;
			display: grid;
			grid-auto-flow: column;

			&.-nav_drop_company {
				grid-template-rows: repeat(1, 1fr);
				grid-template-columns: repeat(5, 1fr);

				> li {
					&:first-child {
						width: 290px;
						grid-column: 1 / 1;
						grid-row: 1 / 3;
					}
					&:nth-child(2) {
						grid-column: 2;
						grid-row: 1/3;
						margin-left: 50px;
					}
					&:nth-child(3) {
						grid-column: 3;
						grid-row: 1/3;
						margin-left: 40px;
					}
					&:nth-child(4) {
						grid-column: 4;
						grid-row: 1/3;
						margin-top: 0;
						margin-left: 40px;

						.pc-header_nav_drop_textLink {
							&:not(:first-child) {
								margin-top: 30px;
							}
						}
					}
					&:nth-child(5) {
						grid-column: 5;
						grid-row: 1/3;
						margin-left: 20px;
					}
				}
			}

			&.-nav_drop_service {
				grid-template-rows: repeat(1, 1fr);
				grid-template-columns: repeat(5, 1fr);

				> li {
					&:first-child {
						width: 290px;
						grid-column: 1;
						grid-row: 1;
					}
					&:nth-child(2) {
						grid-column: 2;
						grid-row: 1;
						margin-left: 20px;
					}
					&:nth-child(3) {
						grid-column: 3;
						grid-row: 1;
						margin-left: 30px;
					}
					&:nth-child(4) {
						grid-column: 4;
						grid-row: 1;
						margin-left: 30px;
						margin-top: 0;
					}
					&:nth-child(5) {
						grid-column: 5;
						grid-row: 1;
						margin-left: 30px;
					}
				}

			}

			&.-nav_drop_ir {
				grid-template-rows: repeat(2, 1fr);
				grid-template-columns: repeat(5, 1fr);

				> li {
					&:first-child {
						width: 290px;
						grid-column: 1 / 1;
						grid-row: 1 / 2;
					}
					&:nth-child(2) {
					}
					&:nth-child(3) {
						grid-column: 3;
						grid-row: 1/2;
						margin-left: 45px;
					}
					&:nth-child(4) {
						grid-column: 3;
						grid-row: 2/2;
						margin-top: 30px;
						margin-left: 45px;
					}
					&:nth-child(5) {
						grid-column: 4;
						grid-row: 1/2;
						margin-left: 45px;
					}
					&:nth-child(6) {
						grid-column: 4;
						grid-row: 2/2;
						margin-top: 30px;
						margin-left: 45px;
					}
					&:nth-child(7) {
						grid-column: 5;
						grid-row: 1/2;
					}
					&:nth-child(8) {
						grid-column: 5;
						grid-row: 2/2;
						margin-top: 30px;
					}
				}
			}

			&.-nav_drop_sustainability {
				grid-template-rows: repeat(1, 1fr);
				grid-template-columns: repeat(5, 1fr);

				> li {
					&:first-child {
						width: 290px;
						grid-column: 1;
						grid-row: 1;
					}
					&:nth-child(2) {
						grid-column: 2;
						grid-row: 1;
						margin-left: 50px;
					}
					&:nth-child(3) {
						grid-column: 3;
						grid-row: 1;
						margin-left: 40px;
						width: 230px;
					}
					&:nth-child(4) {
						grid-column: 4;
						grid-row: 1;
						width: 250px;
						margin-left: 20px;
						margin-top: 0;

						.pc-header_nav_drop_textLink {
							&:not(:first-child) {
								margin-top: 30px;
							}
						}
					}
				}
			}
		}

		&_mainLink {
			height: 60px;
			font-size: 2.6rem;
			color: #222;
			padding-right: 80px;
			display: inline-flex;
			align-items: center;
			position: relative;
			transition: all .2s ease;

			&::after {
				content: '';
				position: absolute;
				width: 60px;
				height: 60px;
				top: 0;
				right: 0;
				background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
				background-size: 16px 9px;
			}

			span {
				position: absolute;
				top: 0;
				right: 0;

				.circle_animation {
					width: 60px;
					height: 60px;

					.outer {
						stroke: $main-color;
						stroke-width: 1;
						fill: transparent;
						stroke-dashoffset: 600;
						transition: stroke-dashoffset 1.5s;
					}
				}
			}

			&:hover {
				color: $main-color;

				.circle_animation {
					.outer {
						stroke: $main-color;
						stroke-width: 1;
						stroke-dasharray: 600;
						stroke-dashoffset: 0;
					}
				}

			}
		}

		&_subLink {
			margin-top: 20px;
			padding-right: 26px;
			font-size: 1.6rem;
			position: relative;
			display: block;
			color: $main-color;

			.icon-arrow {
				position: absolute;
				bottom: 6px;
				top: inherit;
			}

			&:hover {
				.icon-arrow {
					&::after {
						animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
					}
				}
			}
		}

		&_imgLink {
			width: 200px;
			grid-column: 2;
			grid-row: 1 / 5;

			.link_img {
				width: 200px;
			}

			a {
				&:hover {
					img {
						transform: scale(1.1);
					}
					.icon-arrow {
						&::after {
							animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
						}
					}
				}
			}
		}

		&_textLink {
			li {
				a {
					display: inline-block;
					text-indent: 0;
					line-height: 1.4;
					transition: all .2s ease;
					border-bottom: 1px solid transparent;
				}

				&:first-child {
					margin-bottom: 15px;

					a {
						font-size: 1.8rem;
						color: #222;

						&:hover {
							color: $main-color;
							border-color: $main-color;
						}
					}
				}

				&:not(:first-child) {
					margin-top: 10px;

					a {
						font-size: 1.4rem;
						color: #636363;
						margin-left: 16px;
						position: relative;
						transition: all .2s ease;

						&::before {
							content: '';
							width: 8px;
							height: 1px;
							background-color: #ccd2d6;
							position: absolute;
							top: 50%;
							left: -16px;
							transition: all .2s ease;
						}

						&:hover {
							color: $main-color;
							border-color: $main-color;

							&::before {
								background-color: $main-color;
							}
						}
					}
				}
			}
		}

		&.is-active {
			border-bottom: 1px solid #ccd2d6;
		}
	}
}

.sp-header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: #fff;
	border-bottom: 1px solid transparent;
	transition: .3s;

	@include pc() {
		display: none;
	}

	.-logo_white {
		display: none;
	}
	.-logo_blue {
		display: block;
	}

	&.is-transparent {
		background-color: transparent;

		.-logo_white {
			display: block;
		}
		.-logo_blue {
			display: none;
		}
	}

	&.is-white {
		background-color: #fff;

		.-logo_white {
			display: none;
		}
		.-logo_blue {
			display: block;
		}
	}

	&.is-hide {
		transform: translateY(-100%);
	}

	&.is-fixed {
		background-color: #fff;
		border-bottom: 1px solid #ccd2d6;
		transform: translateY(0);

		.-logo_white {
			display: none;
		}
		.-logo_blue {
			display: block;
		}
	}

	&_wrap {
		width: 100%;
		height: 55px;
		display: flex;
		align-items: center;
	}

	&_logo {
		width: 188px;
		margin-left: 15px;
	}

	&_faq {
		width: 92px;
		height: 100%;
		margin-left: auto;

		a {
			height: 100%;
			background-color: $main-color;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 1.2rem;
			text-align: center;
			line-height: 1.4;
		}
	}

	&_menu {
		width: 50px;
		height: 100%;
		background-color: $sub-color;
		position: relative;

		span {
			width: 24px;
			height: 1px;
			margin-top: 20px;
			background-color: #fff;
			transition: all .3s;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 13px;
			will-change: transform;
			transform: rotate(0);

			&:nth-child(2) {
				margin-top: 28px;
			}

			&:last-child {
				margin-top: 36px;
			}
		}

		&.is-open {
			span {
				&:first-child {
					transform: translateY(8px) rotate(-45deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:last-child {
					transform: translateY(-8px) rotate(45deg);
				}
			}
		}
	}
	&_nav {
		height: calc(100vh - 55px);
		padding: 25px 15px;
		background-color: #fff;
		overflow: scroll;
		display: none;
	}
	&_nav_search {
		form {
			background-color: #f3f2f8;
			position: relative;
		}
		input {
			width: 100%;
			height: 50px;
			padding: 14px 15px;
			box-sizing: border-box;
			font-size: 1.6rem;

			&:focus {
				outline: 2px solid $sub-color;
			}
		}
		button {
			width: 50px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 0;
			background: $sub-color url(/search/img/icon01.png) center center no-repeat;
			background-size: 21px 21px;

			img {
				width: 21px;
			}
		}
	}
	&_nav_item {
		border-bottom: 1px solid #ccd2d6;
		padding: 20px 5px;

		>p,>a {
			display: block;
			font-size: 1.8rem;
			line-height: 1.4;
		}

		> p {
			position: relative;

			&::after {
				content: '';
				width: 11px;
				height: 6px;
				background: url(/common/img/icon_arrow_head_blue.png) no-repeat center;
				background-size: contain;
				position: absolute;
				top: 10px;
				right: 5px;
			}
		}

		&:last-child {
			border: none;
			p {
				&::after {
					display: none;
				}
			}
		}

		&.-nav_recruit {
			p {
				&::after {
					display: inline-block;
					position: relative;
					height: 11px;
					background: url(/common/img/icon_blank_blue.png) no-repeat center;
					background-size: contain;
					top: -2px;
					right: -5px;
					transform: rotate(0);
				}
			}
		}

		&.is-open {
			> p {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
	}
	&_nav_sub {
		display: block;
		font-size: 1.5rem;
		line-height: 1.4;
		margin-top: 15px;

		& + ul {
			margin-bottom: 20px;
		}
	}

	&_nav_textLink {
		display: block;
		padding-left: 16px;
		margin-top: 15px;
		font-size: 1.4rem;
		color: #636363;
		position: relative;

		&::before {
			content: '';
			width: 8px;
			height: 1px;
			background-color: #636363;
			position: absolute;
			top: 50%;
			left: 0;
		}
	}
	&_nav_itemInner {
		padding: 15px 20px 0 20px;
		display: none;

		li {
			&:last-child {
				.sp-header_nav_sub {
					margin-bottom: 0;
				}
				ul {
					margin-bottom: 10px;
				}
			}
		}
	}
}

.simple_header {
	position: absolute;
	width: 100%;
	@include pc() {
		height: 90px;
	}

	@include sp() {
		height: 55px;
	}
	.header_inner {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}
	.header_container {
		width: 100%;
		height: 100%;
	}
	.header_logo {
		@include pc() {
			width: 266px;
			margin-left: 50px;
		}
		@include sp() {
			width: 188px;
			margin: 0 auto;
		}
		a {
			display: block;
		}
	}
}
