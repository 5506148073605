@import 'src/common/css/global/_base';

/*================================
	common
=================================*/
main {
	@include pc() {
		width: 100%;
		min-width: 1260px;
	}
}

body:not(#top){
	@include pc() {
		margin-top: 100px;
	}
	@include sp() {
		margin-top: 55px;
	}
}

body#form{
	margin-top: 0;

	.simple_header {
		top: 0;
	}

	.md_h1 {
		text-align: center;
		@include sp() {
			margin-top: 90px;
		}
	}
	.contact_formWrap {
		@include sp() {
			margin: 0 15px;
		}
	}
	.description-block a:after {
    background: url(/common/img/icon_blank_blue.png) no-repeat 50%;
    background-size: contain;
	}
	.form-column-select:after {
    background: url(/common/img/icon_arrow_head_blue.png) no-repeat 50%;
    background-size: contain;
	}
	.button-group button.button-confirm:after,
	.button-group button.button-submit:after {
    background: url(/common/img/icon_arrow_right_white.png) no-repeat 50%;
    background-size: 100%;
	}
	.button-group button.button-back:after {
    background: url(/common/img/icon_arrow_right_blue.png) no-repeat 50%;
    background-size: 100%;
	}
}

body.is-fixed {
	position: relative;

	&::after {
		content: '';
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 0;
		background-color: #000;
		opacity: .5;
		transition: opacity .2s ease;
	}
}

/*================================
	common link & button
=================================*/
.link_img {
	display: inline-block;

	figure {
		overflow: hidden;
	}

	img {
		transition: all .2s ease;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}

.link_text {
	font-size: 1.6rem;
	line-height: 2;
	color: #222;
	border-bottom: 1px solid transparent;
	transition: all .2s ease;

	&:hover {
		border-bottom-color: $main-color;
	}
}

.link_circle {
	display: inline-block;
	
	@include pc() {
		height: 50px;
	}
	@include sp() {
		height: 60px;
	}

	a {
		height: 100%;
		padding-right: 70px;
		font-size: 2rem;
		color: #222;
		display: inline-flex;
		align-items: center;
		position: relative;
		transition: all .2s ease;

		@include sp() {
			font-size: 2rem;
			padding-right: 70px;
		}

		&::after {
			content: '';
			width: 50px;
			height: 50px;
			background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
			background-size: 16px 9px;
			position: absolute;
			top: 0;
			right: 0;

			@include sp() {
				width:60px;
				height: 60px;
			}
		}

		&:hover {
			color: $main-color;
			
			.circle_animation {
				.outer {
					stroke: $main-color;
					stroke-width: 1;
					stroke-dasharray: 600;
					stroke-dashoffset: 0;
				}
			}

		}

		span {
			position: absolute;
			top: 0;
			right: 0;
		}

		.circle_animation {
			height: 50px;
			width: 50px;
			@include sp() {
				width: 60px;
				height: 60px;
			}

			.outer {
				stroke: #006;
				stroke-width: 1;
				fill: transparent;
				stroke-dashoffset: 600;
				transition: stroke-dashoffset 1.5s;
			}
		}
	}

	&.link_circle_white {
		a {
			height: 100%;
			color: #fff;
	
			&::after {
				background: url(/common/img/icon_arrow_right_white.png) no-repeat 50%;
				background-size: 16px 9px;
			}
	
			.circle_animation {
				.outer {
					stroke: #fff;
				}
			}
		}
	}
}

.link_arrow {
	font-size: 1.6rem;
	line-height: 2;
	color: #222;
	transition: all .2s ease;

	&:hover {
		color: $main-color;

		.icon-arrow {
			&::after {
				animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
			}
		}
	}
}

.btn_rect_white {
	position: relative;
	width: 320px;
	height: 85px;
	overflow: hidden;
	@include sp() {
		width: 300px;
		height: 84px;
	}
	
	a {
		height: 100%;
		box-sizing: border-box;
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		border: 1px solid $main-color;
		padding: 18px 25px;
		color: $main-color;
		
		@include sp() {
			padding: 12px 15px;
			font-size: 1.7rem;
		}
	}
	
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: $main-color;
		transition: transform .5s ease;
		transform: translateX(-100%);
		transform-origin: right left;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
	}

	&::after {
		content: '';
		position: absolute;
		width: 16px;
		height: 9px;
		background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
		background-size: 100%;
		top: calc(50% - 3px);
		right: 25px;
		
		@include sp() {
			right: 15px;
		}
	}
	
	&:hover {		
		&::before {
			transform-origin: left right;
			transform: translateX(100%);
		}
	}
}

.btn_rect_blue {
	position: relative;
	width: 320px;
	height: 85px;
	overflow: hidden;
	border: 1px solid $main-color;
	cursor: pointer;
	@include sp() {
		width: 300px;
		height: 84px;
	}
	
	a {
		height: 100%;
		box-sizing: border-box;
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		padding: 18px 25px;
		color: #fff;
		background-color: $main-color;
		
		@include sp() {
			padding: 12px 15px;
			font-size: 1.7rem;
		}
	}
	
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: #fff;
		transition: transform .5s ease;
		transform: translateX(-100%);
		transform-origin: right left;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}

	&::after {
		content: '';
		position: absolute;
		width: 16px;
		height: 9px;
		background: url(/common/img/icon_arrow_right_white.png) no-repeat center;
		background-size: 100%;
		top: calc(50% - 3px);
		right: 25px;
		
		@include sp() {
			right: 15px;
		}
	}
	
	&:hover {		
		&::before {
			transform-origin: left right;
			transform: translateX(100%);
		}
	}
}

/*================================
	common icon
=================================*/
.icon-arrow {
	width: 16px;
	height: 9px;
	margin-left: 10px;
	overflow: hidden;
	position: relative;
	display: inline-block;
	top: -3px;

	&::after {
		content: '';
		width: 16px;
		height: 9px;
		background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
		background-size: contain;
		position: absolute;
    right: 0;
    top: 0;
	}
}

.icon-pdf {
	display: inline-block;
	width: 14px;
	height: 14px;
	background: url(/common/img/icon_pdf.png) no-repeat center;
	background-size: contain;
	vertical-align: middle;
	margin-left: 5px;
	margin-top: -3px;
}

.icon-blank {
	display: inline-block;
	width: 11px;
	height: 11px;
	background: url(/common/img/icon_blank_blue.png) no-repeat center;
	background-size: contain;
	margin-left: 5px;
}

.file-size {
	display: inline-block;
	margin-left: 5px;
	margin-top: -3px;
	color: #7a7a7a;
	font-size: 1.2rem;
	vertical-align: middle;

	@include sp() {
		font-size: 1.1rem;
	}
}

/*================================
	common alignment
=================================*/
.txt_center {
	text-align: center;
}

.txt_right {
	text-align: right !important;
}

.align_center {
	margin: 0 auto;
}

.align_right {
	margin-left: auto !important;
}


/*================================
	下層ページ wrapper
=================================*/
.noMV_page {
	@include pc() {
		margin-top: -100px;
		padding-top: 100px;
		min-width: 1260px;
	}
	@include sp() {
		margin-top: -55px;
		padding-top: 55px;
	}
}

.mv_page {
	@include pc() {
		margin-top: -100px;
		padding-top: 100px;
		min-width: 1260px;
	}
	@include sp() {
		margin-top: -55px;
		padding-top: 55px;
	}
}

/*================================
	news list
=================================*/
.news {
	&_wrap {
		@include pc() {
			margin-top: 70px;
			display: flex;
			justify-content: space-between;
		}
		@include sp() {
			margin-top: 50px;
		}
	}
	&_head {
		@include sp() {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		h3 {
			font-family: $alphanumeric;
			font-weight: 300;
			color: $main-color;
			font-size: 4.8rem;

			@include sp() {
				font-size: 3rem;
			}
		}
	}
	&_btn {
		@include pc() {
			margin-top: 50px;
			width: 227px;
			height: 60px;
		}

		@include sp() {
			width: 150px;
			height: 40px;
		}
		a {
			@include sp() {
				font-size: 1.3rem;
			}
		}
	}
	&_list {
		width: 100%;

		@include pc() {
			margin-left: 70px;
		}
		@include sp() {
			margin-top: 40px;
		}
	
		&_item {
			width: 100%;
			display: flex;
			align-items: center;
			position: relative;
			
			@include pc() {
				padding: 30px 29px 30px 0;	
			}
			@include sp() {
				padding: 20px 16px 20px 0;
				flex-wrap: wrap;
			}
	
			&:first-child {
				padding-top: 15px;
			}
	
			a {
				@include pc() {
					margin-left: 30px;
					transition: all .2s ease;
					flex: 1;
				}
				@include sp() {
					width: 100%;
					margin-top: 15px;
					padding-right: 20px;
				}
				
				&:hover {
					color: $main-color;
					text-decoration: underline;
					text-underline-offset: 0.5rem;
					
					& + .icon-arrow {
						&::after {
							animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
						}
					}
				}
			}
			.icon-arrow {
				position: absolute;
				right: 0;
				top: calc(50% - 4px);
			}
		}
	}
}
.-news_date {
	font-size: 1.5rem;
	color: #7a7a7a;

	@include sp() {
		font-size: 1.4rem;
	}
}
.-news_label {
	width: 72px;
	height: 20px;
	margin-left: 30px;
	color: #fff;
	font-size: 1.1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	@include sp() {
		margin-left: 14px;
	}

	&.-news_ir {
		width: 100px;
	}
	&.-label_01 {
		background-color: #1193dd;
	}
	&.-label_02 {
		background-color: #064592;
	}
	&.-label_03 {
		background-color: #5c319d;
	}
	&.-label_04 {
		background-color: #394dc8;
	}
	&.-label_05 {
		background-color: #13908f;
	}
}
.-news_txt {
	display: inline;
	font-size: 1.5rem;
	line-height: 1.5;
	transition: all .2s ease;
	border-bottom: 1px solid transparent;

	@include pc() {
		max-width: 660px;
	}
	@include sp() {
		font-size: 1.4rem;
	}
}

/*================================
	contact
=================================*/
.contact {
	width: 100%;
	@include pc() {
		margin-top: 100px;
		background: url(/common/img/bg_contact_pc.jpg) no-repeat center;
		background-size: cover;
	}
	@include sp() {
		margin-top: 60px;
		background: url(/common/img/bg_contact_sp.jpg) no-repeat center;
		background-size: cover;
	}

	&_inner {
		display: flex;
		justify-content: space-between;

		@include pc() {
			width: 1200px;
			margin: 0 auto;
			padding: 70px 0 85px;
			align-items: center;
		}
		@include sp() {
			margin: 0 15px;
			padding: 60px 0 40px;
			flex-direction: column;
		}
	}

	&_head {
		h2 {
			font-family: $alphanumeric;
			font-weight: 300;
			color: $main-color;
			@include pc() {
				font-size: 7.2rem;
			}
			@include sp() {
				font-size: 4.8rem;
			}
		}

		p {
			color: $main-color;

			@include pc() {
				margin-top: 30px;
				font-size: 1.6rem;
			}
			@include sp() {
				margin-top: 20px;
				font-size: 1.5rem;
			}
		}
	}
	&_btn {
		@include pc() {
			height: 100px;
		}
		@include sp() {
			display: inherit;
			margin-top: 45px;
			margin-left: auto;
			height: 60px;
		}

		a {
			color: $main-color;
			@include pc() {
				padding-right: 120px;
				font-size: 2.4rem;
			}
			@include sp() {
				padding-right: 80px;
				font-size: 2rem;
			}
			.circle_animation {
				@include pc() {
					width: 100px;
					height: 100px;		
				}
				@include sp() {
					width: 60px;
					height: 60px;
				}
			}

			&::after {
				@include pc() {
					width: 100px;
					height: 100px;
				}
				@include sp() {
					width: 60px;
					height: 60px;
				}
			}
		}

	}
}

/*================================
	breadcrumbs
=================================*/
.breadcrumbs {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;

	@include pc() {
		min-width: 1260px;
		margin-top: 100px;
		padding-top: 20px;
	}
	@include sp() {
		margin-top: 55px;
		padding-top: 15px;

		& + .noMV_page {
			padding-top: 88px;
		}
	}

	&_pc {
		@include pc() {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			align-items: center;

			li {
				display: inline;
				font-size: 1.4rem;
				color: $main-color;

				a {
					font-size: 1.4rem;
					color: #7a7a7a;
				}

				&:not(:first-child) {
					padding-left: 34px;
					position: relative;

					&::before {
						content: '';
						width: 5px;
						height: 5px;
						border-radius: 50%;
						background-color: #7a7a7a;
						position: absolute;
						top: calc(50% - 3px);
						left: 15px;
					}
				}
			}
		}
		@include sp() {
			display: none;
		}
	}
	&_sp {
		@include pc() {
			display: none;
		}
		@include sp() {
			margin: 0 15px;
			padding-left: 16px;
			font-size: 1.3rem;
			color: #7a7a7a;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				width: 11px;
				height: 6px;
				background: url(/common/img/icon_arrow_head_blue.png) no-repeat 50%;
				background-size: contain;
				transform: rotate(90deg);
				top: 7px;
				left: 0;
			}
		}
	}

	&.is-white {
		li {
			color: #fff;
			a {
				color: #fff;
			}

			&:not(:first-child) {

				&::before {
					background-color: #fff;
				}
			}
		}

		.breadcrumbs_sp {
			a {
				color: #fff;
			}
			&::before {
				background: url(/common/img/icon_arrow_head_white.png) no-repeat 50%;
				background-size: contain;
			}
		}
	}
}

/*================================
	サイドナビ
=================================*/
.side_nav {
	&_wrapper {
		display: flex;
		flex-wrap: wrap;
		@include pc() {
			min-width: 1200px;
			margin: 0 auto;
			justify-content: center;
		}
		@include sp() {
			padding-bottom: 60px;
		}

		.md_content {
			@include pc() {
				width: 950px;
				margin: 0;
				order: 2;
			}
			@include sp() {
				order: 1;
			}
		}

		.contact {
			@include pc() {
				order: 3;
			}
			@include sp() {
				order: 2;
			}
		}

		&.side_fourCol {
			.pickupNav {
				@include pc() {
					order: 3;
				}
				@include sp() {
					order: 2;
				}
			}
			.contact {
				@include pc() {
					order: 4;
				}
				@include sp() {
					order: 3;
				}
			}
			.side_nav_inner {
				@include sp() {
					order: 4;
				}
			}
		}
	}

	&_inner {
		@include pc() {
			width: 205px;
			margin-top: 100px;
			margin-right: 45px;
			order: 1;
		}
		@include sp() {
			width: 100%;
			margin: 60px 15px 0;
			order: 3;

			.link_circle {
				width: 100%;
				
				a {
					width: 100%;

					&:after {
						width: 16px;
					}
				}
				span {
					display: none;
				}
			}
		}

		&.-sustainability {
			@include pc() {
				width: 220px;
				margin-right: 30px;
				
				.link_circle a {
					padding-right: 56px;
				}
			}
		}
	}
	&_list {
		@include pc() {
			margin-top: 40px;
		}
		@include sp() {
			padding-top: 10px;
			border-top: 1px solid #ccd2d6;
		}

		li {
			@include pc() {
				margin-top: 30px;
			}
			@include sp() {
				margin-top: 20px;
			}

			a {
				border-bottom: 1px solid transparent;
				@include pc() {
					font-size: 1.8rem;
					transition: all .2s ease;

					&:hover {
						color: $main-color;
						border-bottom-color: $main-color;
					}
				}
				@include sp() {
					font-size: 1.5rem;
				}
			}

			&.is-current {
				> a {
					color: $main-color;
					font-weight: 500;
					border-bottom-color: $main-color;
				}
			}
		}
	}
	&_subList {
		li {
			position: relative;

			@include pc() {
				margin-top: 10px;
				padding-left: 20px;

				&:first-child {
					margin-top: 15px;
				}
			}
			@include sp() {
				margin-top: 15px;
			}

			a {
				display: inline-block;
				color: #636363;
				font-size: 1.4rem;
				border-bottom: 1px solid transparent;
				transition: all .2s ease;

				@include pc() {
					&:hover {
						color: $main-color;
						border-bottom-color: $main-color;

						&::before {
							background-color: $main-color;
						}
					}
				}
				@include sp() {
					margin-left: 25px;
				}
				
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 10px;
					width: 8px;
					height: 1px;
					background-color: #636363;
	
					@include sp() {
						left: 10px;
					}
				}
			}
			
			&.is-current {
				a {
					color: $main-color;
					font-weight: 500;
					border-bottom-color: $main-color;

					&::before {
						background-color: $main-color;
					}
				}
			}
		}
	}
}

/*================================
	ローカルナビ
=================================*/
.local_nav {
	@include pc() {
		min-width: 1260px;
		margin-top: 140px;
		border-top: 1px solid #ccd2d6;
	}
	@include sp() {
		display: none;
	}

	&_inner {
		@include pc() {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			align-items: center;
		}
	}
	&_parent {
		@include pc() {
			width: 255px;
	
			a {
				font-size: 1.8rem;
				color: $main-color;
			}
		}
	}
	&_child {
		@include pc() {
			padding: 50px 60px;
			border-left: 1px solid #ccd2d6;

			ul {
				display: flex;
				flex-wrap: wrap;
				margin-top: -25px;
				margin-left: -25px;
	
				li {
					margin: 25px 25px 0;
					a {
						border-bottom: 1px solid transparent;
						font-size: 1.6rem;
						transition: all .2s ease;
	
						&:hover {
							color: $main-color;
							border-bottom-color: $main-color;
						}
					}

					&.is-current {
						a {
							color: $main-color;
							font-weight: 500;
							border-bottom-color: $main-color;
						}
					}
				}
			}
		}
	}

	&.-no-border {
		border: none;
		margin-top: 0;
	}
}

/*================================
	pickup ナビ
=================================*/
.pickupNav {
	width: 100%;
	@include pc() {
		margin-top: 203px;
		background: url(/common/img/bg_pickupNav_pc.jpg) no-repeat bottom right;
		background-size: 100%;
	}
	@include sp() {
		margin-top: 139px;
		background: url(/common/img/bg_pickupNav_sp.jpg) no-repeat bottom right;
		background-size: 100%;
	}
	&_ttl {
		font-family: $alphanumeric;
		font-weight: 300;
		color: $main-color;
		position: relative;

		@include pc() {
			width: 1200px;
			font-size: 4.8rem;
			margin: 0 auto 40px;
			// padding-bottom: 146px;
		}
		@include sp() {
			font-size: 3rem;
			padding-bottom: 104px;
			margin: 0 15px;
		}
	}
	&_inner {
		@include pc() {
			width: 1200px;
			margin: 0 auto;
			padding-bottom: 42px;
		}
		@include sp() {
			margin: 0 15px;
			padding-bottom: 60px;
		}
	}
	&_item {
		
		@include pc() {
			margin-top: -103px;
			width: 590px;
		}
		@include sp() {
			margin-top: -79px;
			width: 100%;
		}

		.link_arrow {
			i {
				&::after {
					background: url(/common/img/icon_arrow_right_white.png) no-repeat center;
					background-size: 100%;
				}
			}
			p {
				color: #fff;
				margin-top: 21px;
				@include pc() {
					font-size: 2.4rem;
				}
				@include sp() {
					font-size: 2rem;
				}
			}
		}
	}

	& + .contact {
		margin-top: 0;
	}

	&_2col {
		@include sp() {
			background: url(/common/img/bg_pickupNav_sp_02.jpg) no-repeat 100% 100%;
			background-size: 100%;
		}

		.pickupNav_inner {
			display: flex;

			@include sp() {
				flex-direction: column;
				padding-bottom: 60px;
			}
		}
		.pickupNav_item {
			@include pc() {
				width: 385px;
				margin-right: 20px;
			}
			@include sp() {
				width: 100%;

				&:not(:first-child){
					margin-top: 30px;
				}
			}
		}
	}

	&.-pickNav_company {
		@include pc() {
			background: url(/common/img/bg_pickupNav_pc_03.jpg) no-repeat bottom right;
			background-size: 100%;
		}
		@include sp() {
			background: url(/common/img/bg_pickupNav_sp_03.jpg) no-repeat bottom right;
			background-size: 100%;
		}
	}
}

/*================================
	3下層　MV
=================================*/
.thirdLayer {
	&_mv {
		@include pc() {
			height: 36.61vw;
			max-height: 500px;
			min-height: 460px;
		}

		figure {
			height: 100%;
		}
	}
	&_titleWrap {		
		position: relative;

		@include pc() {
			width: 1260px;
			margin: 0 auto;
		}
		@include sp() {
			margin: 0 15px;
		}
	}
	&_titleInner {
		position: absolute;
		left: 0;
		display: flex;
		align-items: baseline;
		@include pc() {
			width: calc(100% - 60px);
			margin: 0 30px;
			bottom: 85px;
		}
		@include sp() {
			bottom: 45px;
		}
	}
	&_titleJp {
		color: #fff;
		@include pc() {
			font-size: 4.2rem;
		}
		@include sp() {
			font-size: 3rem;
		}
	}
	&_titleEn {
		color: #fff;
		font-family: $alphanumeric;
		font-weight: 300;
		position: relative;
		@include pc() {
			padding-left: 50px;
			font-size: 2.4rem;
		}
		@include sp() {
			padding-left: 30px;
			font-size: 1.8rem;
		}

		&::before {
			content: '';
			position: absolute;
			background: url(/common/img/img_thirdLayer_title_line.png) no-repeat center;
			background-size: contain;
			@include pc() {
				width: 26px;
				height: 41px;
				top: -12px;
				left: 15px;
			}
			@include sp() {
				width: 15px;
				height: 30px;
				top: -6px;
				left: 10px;
			}
		}
	}
}

/*================================
	Adobe Acrobat Reader
=================================*/
.adobe_reader {
	display: flex;
	@include pc() {
		margin-top: 80px;
	}
	@include sp() {
		margin-top: 50px;
		flex-direction: column;
	}

	figure {
		width: 158px;
		margin-right: 20px;
		margin-top: 10px;
	}

	p {
		line-height: 2;
		@include pc() {
			font-size: 1.6rem;
		}
		@include sp() {
			margin-top: 25px;
			font-size: 1.5rem;
		}
	}
}

/*================================
	Accordion
=================================*/
.accord {

	&_list {
		border-top: 1px solid #ccd2d6;
		
		&:last-child {
			border-bottom: 1px solid #ccd2d6;
		}
	}
	&_Q {
		line-height: 1.6;
		position: relative;
	
		@include pc() {
			font-size: 1.8rem;
			padding: 25px 59px 25px 52px;
			transition: all .2s ease;
			cursor: pointer;
	
			&:hover {
				background-color: #f3f2f8;
			}
		}
		@include sp() {
			font-size: 1.7rem;
			padding: 20px 39px 20px 25px;
		}
	
		&::before {
			content: 'Q';
			font-weight: 500;
			font-family: $alphanumeric;
			font-weight: 300;
			color: $main-color;
			position: absolute;
			@include pc() {
				font-size: 2.4rem;
				left: 20px;
				top: 20px;
			}
			@include sp() {
				font-size: 2.2rem;
				left: 0;
				top: 17px;
			}
		}
		
		&::after {
			content: '';
			width: 19px;
			height: 19px;
			position: absolute;
			background: url(/common/img/img_plus.png) no-repeat center;
			background-size: contain;
			@include pc() {
				right: 20px;
				top: 25px;
			}
			@include sp() {
				right: 0;
				top: 20px;
			}
		}
	
		&.is-open {
			&::after {
				background: url(/common/img/img_minus.png) no-repeat center;
				background-size: contain;
			}
		}
	}
	&_A {
		line-height: 1.6;
		position: relative;
		display: none;
	
		@include pc() {
			font-size: 1.6rem;
			margin: 20px 0 20px 52px;
			padding-left: 32px;
		}
		@include sp() {
			font-size: 1.5rem;
			margin: 20px 20px 20px 24px;
			padding-left: 25px;
		}
	
		&::before {
			content: 'A';
			font-weight: 500;
			font-family: $alphanumeric;
			font-weight: 300;
			color: $sub-color;
			position: absolute;
			left: 0;
			top: -3px;
			@include pc() {
				font-size: 2.2rem;
			}
			@include sp() {
				font-size: 2rem;
			}
		}

		a {
      display: inline-block;
      margin-top: 15px;
      line-height: 1.4;
      color: $main-color;
      position: relative;

      @include pc() {
        padding-left: 20px;
      }
      @include sp() {
        padding-left: 15px;
      }

      &::before {
        content: '';
        width: 5px;
        height: 6px;
        background: url(/common/img/icon_arrow_head_blue_small.png) no-repeat center;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: 10px;
      }

      &:hover {
        text-decoration: underline;
        text-underline-offset: 0.5rem;
      }
    }
	}
}

/*================================
	Contact Input
=================================*/
.contact {
	&_step {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		
		p {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			span {
				&:first-child {
					width: 50px;
					height: 50px;
					font-size: 2.4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					border: 1px solid $main-color;
					color: $main-color;
				}
				&:last-child {
					margin-top: 10px;
					opacity: .3;
		
					@include pc() {
						font-size: 1.6rem;
					}
					@include sp() {
						font-size: 1.4rem;
					}
				}
			}

			& + p {
				position: relative;
				@include pc() {
					margin-left: 130px;
				}
				@include sp() {
					margin-left: 68px;
				}

				&::before {
					content: '';
					position: absolute;
					top: 25px;
					height: 2px;
					background-color: #ccd2d6;
					
					@include pc() {
						width: 120px;
						left: -125px;
					}
					@include sp() {
						width: 55px;
						left: -62px;
					}
				}
			}
		}

		&#input {
			p {
				&:first-child {
					span {
						&:first-child {
							color: #fff;
							background-color: $main-color;
						}
						&:last-child {
							color: $main-color;
							opacity: 1;
						}
					}
				}
			}
		}
		&#confirm {
			p {
				&:first-child {
					span {
						&:first-child {
							color: $main-color;
							background-color: transparent;
						}
						&:last-child {
							color: #222;
							opacity: .3;
						}
					}
				}
				&:nth-child(2) {
					span {
						&:first-child {
							color: #fff;
							background-color: $main-color;
						}
						&:last-child {
							color: $main-color;
							opacity: 1;
						}
					}
				}
			}
		}
		&#thanks {
			p {
				&:last-child {
					span {
						&:first-child {
							color: #fff;
							background-color: $main-color;
						}
						&:last-child {
							color: $main-color;
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&_complete {
		@include pc() {
			margin: 60px auto 0;
			width: 940px;
		}
		@include sp() {
			margin-top: 40px;
		}
		&_thanks {
			text-align: center;
			@include pc() {
				font-size: 2.4rem;
			}
			@include sp() {
				font-size: 2rem;
			}
		}
		.md_txt {
			@include pc() {
				margin-top: 50px;
			}
			@include sp() {
				margin-top: 40px;
				font-size: 1.5rem;
			}
		}
		.btn_rect_white {
			@include pc() {
				margin: 50px auto 140px;
			}
			@include sp() {
				margin: 40px auto 100px;
			}
			
			a {
				width: 100%;
				justify-content: center;
			}
		}
	}
}

/*================================
	404 not found
=================================*/
.not_found {
	@include pc() {
		padding-top: 100px;
	}
	@include sp() {
		padding-top: 60px;
	}
	h1 {
		color: $main-color;
		text-align: center;
		line-height: 1.5;
		
		@include pc() {
			font-size: 10rem;
		}
		@include sp() {
			font-size: 8rem;
		}
	}
	h2 {
		color: $main-color;
		line-height: 1.4;
		text-align: center;
		
		@include pc() {
			margin-top: -10px;
			font-size: 3rem;
		}
		@include sp() {
			margin-top: -5px;
			font-size: 2.6rem;
		}
	}
	h3 {
		line-height: 1.4;
		text-align: center;

		@include pc() {
			margin-top: 50px;
			font-size: 2.8rem;
		}
		@include sp() {
			margin-top: 30px;
			font-size: 2.2rem;
		}
	}
	.md_txt {
		@include pc() {
			margin-top: 30px;
			text-align: center;
		}
		@include sp() {
			margin-top: 20px;
			font-size: 1.5rem;
		}
	}
	.btn_rect_white {
		@include pc() {
			margin: 50px auto 0;
		}
		@include sp() {
			margin: 30px auto 0;
		}
	}
}

/*================================
	ページャ用
=================================*/
.pagination_wrap {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.prev-page,
.next-page {
	width: 39px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0;
	background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
	background-size: 16px 9px;
	// transition: all .2s ease;
	cursor: pointer;

	&.disabled {
		background: url(/common/img/icon_arrow_right_gray.png) no-repeat center;
		background-size: 16px 9px;
		pointer-events: none;
	}

	&:hover {
		background-color: $main-color;
		background-image: url(/common/img/icon_arrow_right_white.png);
		background-size: 16px 9px;
	}
}
.prev-page {
	margin-right: 10px;
	transform: rotate(180deg);
	&.disabled {
		transform: rotate(0deg);
	}
}
.next-page {
	&.disabled {
		transform: rotate(180deg);
	}
}

.pagination_inner {
	display: flex;
	align-items: center;
	
	li {
		width: 39px;
		height: 48px;
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			color: $main-color;
			font-family: $alphanumeric;
			font-size: 1.5rem;
			font-weight: 500;
		}

		&.current {
			background-color: $main-color;

			a {
				color: #fff;
			}
		}
	}
}


/*================================
	hover underline
=================================*/
@keyframes lefttoright {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
@keyframes righttoleft {
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}

/*
hover arrow slide
*/
@keyframes arrowSlide {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  50.0% {
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
  50.1% {
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
