@import 'src/common/css/global/_base';

/*================================
	footer
=================================*/
footer {
	@include pc() {
		min-width: 1260px;	
	}
}
.footer {
	width: 100%;
	background: $main-color;
	
	&_inner {
		position: relative;
		@include pc() {
			width: 1200px;
			margin: 0 auto;
			padding: 70px 0 85px;
		}
	
		@include sp() {
			padding: 70px 25px 54px;
		}

	}
	a,p {
		color: #fff;
	}

	&_logo {
		width: 180px;

		@include sp() {
			width: 145px;
			margin: 0 auto;
		}
	}

	&_nav {
		
		@include pc() {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}

	&_nav_list {
		@include pc() {
			display: flex;	
		}

		ul {
			display: flex;

			@include sp() {
				flex-wrap: wrap;	
				margin-top: 10px;
			}

			li {
				@include pc() {
					margin-right: 40px;
				}

				@include sp() {
					width: 50%;
					margin-top: 40px;
				}
			}
		}

		a {
			@include pc() {
				font-size: 1.6rem;
				border-bottom: 1px solid transparent;
				transition: all .2s ease;
			}
			@include sp() {
				font-size: 1.8rem;
			}

			.icon-blank {
				background: url(/common/img/icon_blank_white.png) no-repeat center;
				background-size: contain;
			}

			&:hover {
				border-bottom-color: #fff;
			}
		}

		p {
			line-height: 1.25;
			@include pc() {
				padding-left: 40px;
				border-left: 1px solid #fff;
			}

			@include sp() {
				margin-top: 40px;
				padding-top: 40px;
				border-top: 1px solid #fff;
			}
		}
	}

	&_lower {
		@include pc() {
			margin-top: 80px;
			display: flex;
			justify-content: space-between;
		}

		@include sp() {
			margin-top: 40px;
		}

		ul {
			@include pc() {
				display: flex;
			}

			li {
				@include pc() {
					margin-right: 30px;
				}
				@include sp() {
					margin-top: 17px;
				}
				
				a {
					font-size: 1.4rem;
					border-bottom: 1px solid transparent;
					transition: all .2s ease;

					&:hover {
						border-bottom-color: #fff;
					}
				}
			}
		}

		p {
			font-size: 1.4rem;

			@include sp() {
				margin-top: 55px;
				text-align: center;
			}
		}
	}

	.pagetop {
		width: 60px;
		height: 60px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: 20px;
		@include pc() {
			right: 0;
		}
		@include sp() {
			right: 15px;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
			background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
			background-size: 16px 9px;
			transform: rotate(-90deg);
		}
	}

	&.simple_footer {
		@include pc() {
			height: 90px;
		}
		@include sp() {
			height: 76px;
		}
		.footer_inner {
			padding: 0;
			height: 100%;
		}
		.footer_lower {
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				margin-top: 0;
				@include sp() {
					font-size: 1.3rem;
				}
			}
		}
	}
}
